import React from 'react'
import Layout from '../components/layout2'
import Container from '../components/container'
import Box from '../components/box'
import Case from '../components/case'
import Vbox from '../components/vbox'
import Img from '../components/img'
import Summary from '../components/summary'

class RootIndex extends React.Component {
  render() { 
    return <Layout>
      <div style={{position: "relative"}}>
        <svg className='shape1' width="810" height="903" viewBox="0 0 810 903" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 791.592C71.0019 748.536 161.579 753.776 227.241 805.377L247.515 821.309C267.589 837.083 290.497 848.869 315.002 856.029L447.176 894.647C529.687 918.754 618.373 887.442 667.456 816.873L715.28 748.115C729.725 727.347 740.098 704.026 745.851 679.391L803.841 431.038C815.122 382.728 808.045 331.95 783.985 288.565L753.426 233.459C742.978 214.619 740.217 192.47 745.72 171.642C759.713 118.684 719.779 66.8272 665.004 66.8272H638.565C629.727 66.8272 620.925 65.699 612.373 63.4698C612.373 63.4698 472.384 11.1398 378.329 2.46671C229.093 -11.2949 17.0214 66.8268 0 66.8268V791.592Z" fill="#FFF6A4"/>
        </svg>
      </div>
      <Container>
        <h1 style={{marginTop: "32px"}} className='h1'>Kontakta mig</h1>
        <Summary>
          <h4 className='h4'>Skriv till mig på LinkedIn</h4>
          <p className="small">Frida Runnakko</p>
          <h4 className='h4'>Maila mig</h4>
          <p className="small">frida.runnakko@gmail.com</p>
          <h4 className='h4'>Ring eller smsa</h4>
          <p className="small">+46704186212</p>
        </Summary>
      </Container>
    </Layout>
  }
}

export default RootIndex